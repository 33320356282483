<template>
  <div
    class="block-placeholder"
    :style="{
      width,
      height,
      opacity,
      position: absolute ? 'absolute' : undefined,
      top: absolute ? '0px' : undefined,
      left: absolute ? '0px' : undefined,
      borderRadius: radius,
    }"
  >
    <div class="activity"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'BlockPlaceholder',
  props: {
    absolute: { type: Boolean, default: () => false },
    width: { type: String, default: () => '100%' },
    height: { type: String, default: () => '100%' },
    opacity: { type: String, default: () => '1' },
    radius: { type: String, default: () => '5px' },
  },
}
</script>

<style scoped lang="scss">
.block-placeholder {
  position: relative;
  background-color: rgb(205, 211, 213);
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}
.activity {
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(194, 205, 209, 0.05),
    rgba(194, 205, 209, 0.3),
    rgba(194, 205, 209, 0.6),
    rgba(194, 205, 209, 0.3),
    rgba(194, 205, 209, 0.05)
  );
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
</style>
